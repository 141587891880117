body {
  // padding-top: 50px;
  font-size: 0.9rem;
}
//React app uses <main></main> as a container tag.
//avoid whitespace under footer
main {
  min-height: calc(100vh - 340px);
}
footer {
  padding: 35px 0px;
  margin-top: 10px;
  background: #37474f;
  color: white;
  text-align: center;

  h3 {
    font-size: 1.1rem;
    color: white;
  }
  p {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 575px) {
    .col-sm-4 {
      margin-top: 28px;
      text-align: center;
    }
  }

  // .row {
  //   justify-content: space-between;
  //   align-items: center;
  // }
  // display: flex;
  // flex-direction: column;
  a {
    color: white;
  }
  // justify-content: space-between;
  // align-items: center;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

//Loading react app...
#react-app .loading {
  font-size: 42px;
  color: #aaa;
  font-weight: 100;

  text-align: center;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .front_page_camps {
//   img {
//     width: 100%;
//   }
// }
.loginform {
  max-width: 500px;
}
#infoMessage {
  color: $danger;
}

#user-add-allowed-camps {
  label {
    margin: 5px;
  }
}

.text-small {
  font-size: 14px;
}
.form-control {
  font-size: 16px;
}

.mt {
  margin-top: 15px;
}
.mb {
  margin-bottom: 15px;
}
.mtx2 {
  margin-top: 30px;
}
.mbx2 {
  margin-bottom: 30px;
}

.pt {
  padding-top: 15px;
}
.pb {
  padding-bottom: 15px;
}
.ptx2 {
  padding-top: 30px;
}
.pbx2 {
  padding-bottom: 30px;
}

.btn.btn-xs {
  font-size: 16px;
  :active {
    outline: 0;
  }
}
.label {
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  text-align: center;
  vertical-align: middle;
}
.label-info {
  background-color: #3f51b5;
}
.label-success {
  background-color: #05c46b;
}
// .success {
//   color: #05c46b;
// }

.label-danger {
  background-color: #f53b57;
}
.form-check-inline {
  input {
    margin-right: 6px;
  }
}

.jumbotron {
  height: 400px;
  background-image: url("./img/jumbotron-sm.jpg");
  background-size: cover;
  // background-repeat: no-repeat;
  // background-size: auto 100%;
  // background-position: center center;
  // @media screen and (min-width: 900px) {
  //   background-position-y: 86%;
  // }
  border-radius: 0;
  margin-bottom: 10px;

  .container {
    h1 {
      text-align: center;
      text-shadow: 1px 1px 6px #000000;
      color: #f7f7f7;
      padding-top: 100px;
    }
  }
}

.card-img-top {
  width: auto;
  max-width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  max-height: 350px;
  align-content: flex-start;
}

@include media-breakpoint-up(md) {
  .jumbotron {
    background-image: url(../static/img/jumbotron.jpg);
    background-size: cover;
    background-position: center;
  }

  .form-control {
    font-size: 14px;
  }

  .card-img-top {
    align-self: center;
  }
}

table {
  border: 1px solid #ddd;
}

.bootstrap-table {
  table {
    border: unset;
  }
}

.alert {
  p {
    margin-bottom: 0;
  }
}
