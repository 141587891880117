//
// Variables
// --------------------------------------------------

//== Colors

$primary: #e84545; // #337ab7
$success: #4caf50;
$info: #3f51b5;
$warning: #ffc107;
$danger: #c62828;

//== Scaffolding
//
//## Settings for some of the most global styles.

// ** Background color for `<body>`.

$body-bg: #fff;
// ** Global text color on `<body>`.

// ** Global textual link color.
$link-color: $primary;
// ** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
// ** Link hover decoration.
$link-hover-decoration: underline;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
// Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

// By default, this inherits from the `<body>`.
$headings-font-family: "Open Sans", sans-serif;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: $primary;

//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

// Load fonts from this directory.
$icon-font-path: "../fonts/";
// File name for all font files.
$icon-font-name: "glyphicons-halflings-regular";
// Element ID within SVG icon file.
$icon-font-svg-id: "glyphicons_halflingsregular";

//=== Inverted navbar
// Reset inverted navbar basics

$navbar-dark-color: #9d9d9d;
$navbar-dark-bg: #222;

@import "~bootstrap/scss/bootstrap";
@import "./scss/common";
